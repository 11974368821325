

<template>
  <div>
    <Pane :title="pagetitle" />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline">
            <a-form-model-item>
              <a-input placeholder="设计编号" v-model="form.designCode" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input placeholder="项目名称" v-model="form.projectName" />
            </a-form-model-item>

            <a-form-model-item>
              <a-button-group>
                <a-tooltip>
                  <template slot="title"> 刷新 </template>
                  <a-button @click="reset">重置</a-button>
                </a-tooltip>
              </a-button-group>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button-group>
              <a-tooltip>
                <template slot="title"> 导出 </template>
                <a-button @click="download" icon="file-excel"> </a-button>
              </a-tooltip>
            </a-button-group>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="filteredList"
        :loading="loading"
        :pagination="false"
        :scroll="{ x: 3000 }"
      >
        <a-table-column
          title="设计编号"
          data-index="designCode"
          ellipsis="true"
          width="120px"
          fixed="left"
        >
        </a-table-column>

        <a-table-column
          title="项目名称"
          data-index="projectName"
          ellipsis="true"
          width="400px"
          fixed="left"
        >
        </a-table-column>

        <a-table-column
          v-for="item in columns"
          :key="item"
          :title="item"
          width="100px"
          align="right"
        >
          <template slot-scope="text">
            <span :style="moneyColor(text[item])">
              {{ moneyFilter(text[item]) }}
            </span>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import { fetchProjectDetail } from "@/api/shared-cost";
import downloadFile from "@/utils/xlsx";

export default {
  mixins: [watermark],

  data() {
    return {
      form: {},
      loading: false,
      list: [],
      columns: [],

      deptId: "",
      year: "",
    };
  },

  computed: {
    filteredList() {
      let res = [];
      if (this.form.designCode) {
        res = this.list.filter(
          (item) =>
            typeof item.designCode === "string" &&
            item.designCode.indexOf(this.form.designCode.toUpperCase()) > -1
        );
      }
      if (this.form.projectName) {
        res = [
          ...res,
          ...this.list.filter(
            (item) =>
              item.projectName &&
              item.projectName.indexOf(this.form.projectName) > -1
          ),
        ];
      }
      return res.length > 0 ? res : this.list;
    },

    pagetitle() {
      if (this.year && this.list.length > 0) {
        return `${this.year}年${this.list[0].deptName}项目公摊`;
      } else {
        return "";
      }
    },
  },

  mounted() {
    const { query } = this.$route;
    const { deptId, year } = query || {};

    if (deptId && year && this.deptId !== deptId) {
      this.deptId = deptId;
      this.year = year;
      this.getDetail({
        deptId,
        year,
      });
    }
  },

  methods: {
    getDetail(params) {
      this.loading = true;
      fetchProjectDetail(params)
        .then((res) => {
          if (Array.isArray(res)) {
            const filteredList = res.map((item, index) => {
              const obj = {
                deptName: item.deptName,
                designCode: item.designCode,
                projectName: item.projectName,
              };
              if (Array.isArray(item.details)) {
                if (index === 0) {
                  obj.designCode = "--";
                  obj.projectName = "合计";
                  this.columns = item.details.map(
                    (element) => element.subjectCode
                  );
                }

                item.details.forEach((element) => {
                  obj[element.subjectCode] = element.cost;
                });
              }

              return obj;
            });
            this.list = Object.freeze(filteredList);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reset() {
      this.form = {};
    },

    moneyFilter(money) {
      if (typeof money !== "number" || !money) {
        return 0;
      }

      const moneyStr = String(money);
      const arr = moneyStr.split(".");
      if (arr.length > 1) {
        return this.separateMoney(arr[0]) + "." + arr[1];
      } else {
        return this.separateMoney(moneyStr);
      }
    },

    separateMoney(moneyStr) {
      let arr = moneyStr.split("").reverse();

      let result = [];

      for (let i = 0; i < arr.length; i++) {
        if (i && i % 3 === 0) {
          result.push(",");
        }
        result.push(arr[i]);
      }

      return result.reverse().join("");
    },

    moneyColor(money) {
      if (parseFloat(money) < 0) {
        return {
          color: "#f00",
        };
      } else {
        return {
          color: "#1890ff",
        };
      }
    },

    download() {
      downloadFile(this.list, this.pagetitle);
    },
  },
};
</script>
