import request from '../request'
import download from '../download'

export function fetchList(params) {
    return request({
        url: `/finance-service/sharedCost/list`,
        params,
    })
}

// 上传 Excel 文件
export function upload(params, data) {
    return request({
        url: `/finance-service/sharedCost/import?year=${params.year}`,
        method: "post",
        data
    })
}

// 下载模板
export function fetchTemplate() {
    return download({
        url: '/finance-service/sharedCost/importTemplate',
    })
}

export function fetchProjectDetail(params) {
    return request({
        url: '/finance-service/sharedCost/projectList',
        params,
    })
}

// 导出凭证
export function downloadCertificate(data) {
    return download({
        url: '/finance-service/sharedCost/export',
        data,
    })
}
